html.swipebox-html.swipebox-touch{
    overflow:hidden!important
}
#swipebox-overlay img{
    border:none!important
}
#swipebox-overlay{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:99999!important;
    overflow:hidden;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
#swipebox-container{
    position:relative;
    width:100%;
    height:100%
}
#swipebox-slider{
    -webkit-transition:-webkit-transform .4s ease;
    transition:transform .4s ease;
    height:100%;
    left:0;
    top:0;
    width:100%;
    white-space:nowrap;
    position:absolute;
    display:none;
    cursor:pointer
}
#swipebox-slider .slide{
    height:100%;
    width:100%;
    line-height:1px;
    text-align:center;
    display:inline-block
}
#swipebox-slider .slide:before{
    content:"";
    display:inline-block;
    height:50%;
    width:1px;
    margin-right:-1px
}
#swipebox-slider .slide .swipebox-inline-container,#swipebox-slider .slide .swipebox-video-container,#swipebox-slider .slide img{
    display:inline-block;
    max-height:100%;
    max-width:100%;
    margin:0;
    padding:0;
    width:auto;
    height:auto;
    vertical-align:middle
}
#swipebox-slider .slide .swipebox-video-container{
    background:0 0;
    max-width:1140px;
    max-height:100%;
    width:100%;
    padding:5%;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
#swipebox-slider .slide .swipebox-video-container .swipebox-video{
    width:100%;
    height:0;
    padding-bottom:56.25%;
    overflow:hidden;
    position:relative
}
#swipebox-slider .slide .swipebox-video-container .swipebox-video iframe{
    width:100%!important;
    height:100%!important;
    position:absolute;
    top:0;
    left:0
}
#swipebox-slider .slide-loading{
    background:url(../../../resources/assets/css/site-mobile-design/img/loader.gif) center center no-repeat
}
#swipebox-bottom-bar,#swipebox-top-bar{
    -webkit-transition:.5s;
    transition:.5s;
    position:absolute;
    left:0;
    z-index:999;
    height:50px;
    width:100%
}
#swipebox-bottom-bar{
    bottom:-50px
}
#swipebox-bottom-bar.visible-bars{
    -webkit-transform:translate3d(0,-50px,0);
    transform:translate3d(0,-50px,0)
}
#swipebox-top-bar{
    top:-50px;
    color:#fff!important;
    font-size:15px;
    line-height:43px;
    font-family:Helvetica,Arial,sans-serif
}
#swipebox-top-bar.visible-bars{
    -webkit-transform:translate3d(0,50px,0);
    transform:translate3d(0,50px,0)
}
#swipebox-title{
    display:block;
    width:100%;
    text-align:center
}
#swipebox-close,#swipebox-next,#swipebox-prev{
    background-image:url(../../../resources/assets/css/site-mobile-design/img/icons.png);
    background-repeat:no-repeat;
    border:none!important;
    text-decoration:none!important;
    cursor:pointer;
    width:50px;
    height:50px;
    top:0
}
#swipebox-arrows{
    display:block;
    margin:0 auto;
    width:100%;
    height:50px
}
#swipebox-prev{
    background-position:-32px 13px;
    float:left
}
#swipebox-next{
    background-position:-78px 13px;
    float:right
}
#swipebox-close{
    top:0;
    right:0;
    position:fixed;
    z-index:9999;
    background-position:15px 12px
}
.swipebox-no-close-button #swipebox-close{
    display:none
}
#swipebox-next.disabled,#swipebox-prev.disabled{
    opacity:.3
}
.swipebox-no-touch #swipebox-overlay.rightSpring #swipebox-slider{
    -webkit-animation:rightSpring .3s;
    animation:rightSpring .3s
}
.swipebox-no-touch #swipebox-overlay.leftSpring #swipebox-slider{
    -webkit-animation:leftSpring .3s;
    animation:leftSpring .3s
}
.swipebox-touch #swipebox-container:after,.swipebox-touch #swipebox-container:before{
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    -webkit-transition:all .3s ease;
    transition:all .3s ease;
    content:' ';
    position:absolute;
    z-index:999;
    top:0;
    height:100%;
    width:20px;
    opacity:0
}
.swipebox-touch #swipebox-container:before{
    left:0;
    -webkit-box-shadow:inset 10px 0 10px -8px #656565;
    box-shadow:inset 10px 0 10px -8px #656565
}
.swipebox-touch #swipebox-container:after{
    right:0;
    -webkit-box-shadow:inset -10px 0 10px -8px #656565;
    box-shadow:inset -10px 0 10px -8px #656565
}
.swipebox-touch #swipebox-overlay.leftSpringTouch #swipebox-container:before,.swipebox-touch #swipebox-overlay.rightSpringTouch #swipebox-container:after{
    opacity:1
}
@-webkit-keyframes rightSpring{
    0%,100%{
        left:0
    }
    50%{
        left:-30px
    }
}
@keyframes rightSpring{
    0%,100%{
        left:0
    }
    50%{
        left:-30px
    }
}
@-webkit-keyframes leftSpring{
    0%,100%{
        left:0
    }
    50%{
        left:30px
    }
}
@keyframes leftSpring{
    0%,100%{
        left:0
    }
    50%{
        left:30px
    }
}
@media screen and (min-width:800px){
    #swipebox-close{
        right:10px
    }
    #swipebox-arrows{
        width:92%;
        max-width:800px
    }
}
#swipebox-overlay{
    background:#fff
}
#swipebox-bottom-bar,#swipebox-top-bar{
    text-shadow:1px 1px 1px #000;
    background:#000;
    opacity:.95
}
#thumb-image{
    position:fixed;
    height:60px;
    bottom:0;
    left:0;
    right:0;
    background:hsla(0,0%,78%,.6)
}
#thumb-image ul{
    overflow-y:hidden;
    max-width:100%;
    white-space:nowrap;
    text-align:left;
    margin:5px auto;
}
#thumb-image ul li{
    width:50px;
    height:50px;
    margin:0 5px;
    border:1px solid #ccc;
    display:inline-block;
    vertical-align:top;
    text-align:center;
    white-space:normal;
    padding:2px
}
#thumb-image ul li img{
    max-width:100%
}
#thumb-image ul li.current{
    border:1px solid #1565c0
}
