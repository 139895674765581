
@font-face {
    font-family: "segoeui";
    src: url(../fonts/segoeui/segoeui.eot);
    src: local(segoeui), url(../fonts/segoeui/segoeui.woff) format("woff"), url(../fonts/segoeui/segoeui.ttf) format("truetype")
}


html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}


body {
  margin: 0;
}


article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}


[hidden],
template {
  display: none;
}


a {
  background-color: transparent;
}


a:active,
a:hover {
  outline: 0;
}


h1 {
  font-size: 2em;
  margin: 0.67em 0;
}


img {
  border: 0;
}


svg:not(:root) {
  overflow: hidden;
}

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  
  font: inherit;
  
  margin: 0;
  
}


button {
  overflow: visible;
}


button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}


button[disabled],
html input[disabled] {
  cursor: default;
}


button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}


input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  
  padding: 0;
  
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}


input[type="search"] {
  -webkit-appearance: textfield;
  
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


textarea {
  overflow: auto;
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none;
}

ul:not(.browser-default) > li {
  list-style-type: none;
}

a {
  color: #039be5;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.valign-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.clearfix {
  clear: both;
}


.divider {
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
}


i {
  line-height: inherit;
}

i.left {
  float: left;
  margin-right: 15px;
}

i.right {
  float: right;
  margin-left: 15px;
}

i.tiny {
  font-size: 1rem;
}

i.small {
  font-size: 2rem;
}

i.medium {
  font-size: 4rem;
}

i.large {
  font-size: 6rem;
}

img.responsive-img{
  max-width: 100%;
  height: auto;
}

.pagination li {
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  vertical-align: top;
  height: 30px;
}

.pagination li a {
  color: #444;
  display: inline-block;
  font-size: 1.2rem;
  padding: 0 10px;
  line-height: 30px;
}

.pagination li.active a {
  color: #fff;
}

.pagination li.active {
  background-color: #ee6e73;
}

.pagination li.disabled a {
  cursor: default;
  color: #999;
}

.pagination li i {
  font-size: 1.2rem;
    line-height: 32px;
}

.pagination li.pages ul li {
  display: inline-block;
  float: none;
}

@media only screen and (max-width: 992px) {
  .pagination {
    width: 100%;
  }
  .pagination li.prev,
  .pagination li.next {
    width: 10%;
  }
  .pagination li.pages {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
  }
}


.fade-in {
  opacity: 0;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
}


table, th, td {
  border: none;
}

table {
  width: 100%;
  display: table;
}



thead {
  border-bottom: 1px solid #d0d0d0;
}

td, th {
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
}

.hide {
  display: none !important;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.center, .center-align {
  text-align: center;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.no-select, input[type=range],
input[type=range] + .thumb {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.circle {
  border-radius: 50%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-padding {
  padding: 0 !important;
}

span.badge {
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 14px;
  text-align: center;
  font-size: 1rem;
  line-height: 22px;
  height: 22px;
  color: #757575;
  float: right;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

span.badge.new {
  font-weight: 300;
  font-size: 0.8rem;
  color: #fff;
  background-color: #26a69a;
  border-radius: 2px;
}

span.badge.new:after {
  content: " new";
}

span.badge[data-badge-caption]::after {
  content: " " attr(data-badge-caption);
}

nav ul a span.badge {
  display: inline-block;
  float: none;
  margin-left: 4px;
  line-height: 22px;
  height: 22px;
  -webkit-font-smoothing: auto;
}

.collection-item span.badge {
  margin-top: calc(0.75rem - 11px);
}

.collapsible span.badge {
  margin-left: auto;
}

.side-nav span.badge {
  margin-top: calc(24px - 11px);
}



.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}



.container .row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.section.no-pad {
  padding: 0;
}

.section.no-pad-bot {
  padding-bottom: 0;
}

.section.no-pad-top {
  padding-top: 0;
}

.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.row .col {
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 0.75rem;
  min-height: 1px;
}

.row .col[class*="push-"], .row .col[class*="pull-"] {
  position: relative;
}

.row .col.s1 {
  width: 8.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s2 {
  width: 16.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s4 {
  width: 33.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s5 {
  width: 41.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s7 {
  width: 58.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s8 {
  width: 66.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s10 {
  width: 83.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s11 {
  width: 91.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}


nav {
  color: #fff;
  background-color: #ee6e73;
  width: 100%;
  height: 52px;
  line-height:52px;
}

nav.nav-extended {
  height: auto;
}

nav.nav-extended .nav-wrapper {
  min-height: 52px;
  height: auto;
}

nav.nav-extended .nav-content {
  position: relative;
  line-height: normal;
}

nav a {
  color: #fff;
}

nav i.material-icons {
  display: block;
  font-size: 20px;
  height: 52px;
  line-height:52px;
}

nav .nav-wrapper {
  position: relative;
  height: 100%;
}



nav:not(.category) .button-collapse ,nav .go-back{
  float: left;
  position: relative;
  z-index: 1;
  height: 52px;
  margin: 0 18px;
}

nav .button-collapse i {
  height: 52px;
  line-height:52px;
}


nav ul {
  margin: 0;
}

nav ul li {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  float: left;
  padding: 0;
}

nav ul li.active {
  background-color: rgba(0, 0, 0, 0.1);
}

nav ul a {
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  font-size: 1rem;
  color: #fff;
  display: block;
  padding: 0 15px;
  cursor: pointer;
  height: 52px;
}





nav ul.left {
  float: left;
}


nav .brand-logo {
    -webkit-transform: translateX(-50%);
     transform: translateX(-50%); 
}
nav .brand-logo {
    position: absolute;
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
}






a {
  text-decoration: none;
}

html {
  line-height: 1.5;
  font-family: "segoeui", sans-serif;

  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}

@media only screen and (min-width: 0) {
  html {
    font-size: 13px;
  }
}

@media only screen and (min-width: 992px) {
  html {
    font-size: 14.5px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 15px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.1;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 4.2rem;
  line-height: 110%;
  margin: 2.1rem 0 1.68rem 0;
}

h2 {
  font-size: 3.56rem;
  line-height: 110%;
  margin: 1.78rem 0 1.424rem 0;
}

h3 {
  font-size: 2.92rem;
  line-height: 110%;
  margin: 1.46rem 0 1.168rem 0;
}

h4 {
  font-size: 2.28rem;
  line-height: 110%;
  margin: 1.14rem 0 0.912rem 0;
}

h5 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0;
}

h6 {
  font-size: 1rem;
  line-height: 110%;
  margin: 0.5rem 0 0.4rem 0;
}

em {
  font-style: italic;
}

strong {
  font-weight: 500;
}




#toast-container {
  display: block;
  position: fixed;
  z-index: 10000;
}

@media only screen and (max-width: 600px) {
  #toast-container {
    min-width: 100%;
    bottom: 0%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  #toast-container {
    left: 5%;
    bottom: 7%;
    max-width: 90%;
  }
}

@media only screen and (min-width: 993px) {
  #toast-container {
    top: 10%;
    right: 7%;
    max-width: 86%;
  }
}

.toast {
  border-radius: 2px;
  top: 35px;
  width: auto;
  margin-top: 10px;
  position: relative;
  max-width: 100%;
  height: auto;
  min-height: 48px;
  line-height: 1.5em;
  word-break: break-all;
  background-color: #323232;
  padding: 10px 25px;
  font-size: 1.1rem;
  font-weight: 300;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: default;
}

.toast .toast-action {
  color: #eeff41;
  font-weight: 500;
  margin-right: -25px;
  margin-left: 3rem;
}

.toast.rounded {
  border-radius: 24px;
}

@media only screen and (max-width: 600px) {
  .toast {
    width: 100%;
    border-radius: 0;
  }
}

.tabs {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  height: 48px;
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  white-space: nowrap;
}

.tabs.tabs-transparent {
  background-color: transparent;
}

.tabs.tabs-transparent .tab a,
.tabs.tabs-transparent .tab.disabled a,
.tabs.tabs-transparent .tab.disabled a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.tabs.tabs-transparent .tab a:hover,
.tabs.tabs-transparent .tab a.active {
  color: #fff;
}

.tabs.tabs-transparent .indicator {
  background-color: #fff;
}

.tabs.tabs-fixed-width {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tabs.tabs-fixed-width .tab {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.tabs .tab {
  display: inline-block;
  text-align: center;
  line-height: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.tabs .tab a {
  color: #000;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-transition: color .28s ease;
  transition: color .28s ease;
}

.tabs .tab a:hover, .tabs .tab a.active {
  background-color: transparent;
  color: #000;
}

.tabs .tab.disabled a,
.tabs .tab.disabled a:hover {
  color: rgba(238, 110, 115, 0.7);
  cursor: default;
}

.tabs .indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #1565c0;
  will-change: left, right;
}

@media only screen and (max-width: 992px) {
  .tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .tabs .tab {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .tabs .tab a {
    padding: 0 12px;
  }
}

.material-tooltip {
  padding: 10px 8px;
  font-size: 1rem;
  z-index: 2000;
  background-color: transparent;
  border-radius: 2px;
  color: #fff;
  min-height: 36px;
  line-height: 120%;
  opacity: 0;
  position: absolute;
  text-align: center;
  max-width: calc(100% - 4px);
  overflow: hidden;
  left: 0;
  top: 0;
  pointer-events: none;
  visibility: hidden;
}

.backdrop {
  position: absolute;
  opacity: 0;
  height: 7px;
  width: 14px;
  border-radius: 0 0 50% 50%;
  background-color: #323232;
  z-index: -1;
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
  visibility: hidden;
}

.btn, .btn-large,
.btn-flat {
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 46px;
  line-height: 46px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.btn.disabled, .disabled.btn-large,
.btn-floating.disabled,
.btn-large.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-flat:disabled,
.btn[disabled],
[disabled].btn-large,
.btn-floating[disabled],
.btn-large[disabled],
.btn-flat[disabled] {
  pointer-events: none;
  background-color: #DFDFDF !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #9F9F9F !important;
  cursor: default;
}

.btn.disabled:hover, .disabled.btn-large:hover,
.btn-floating.disabled:hover,
.btn-large.disabled:hover,
.btn-flat.disabled:hover,
.btn:disabled:hover,
.btn-large:disabled:hover,
.btn-floating:disabled:hover,
.btn-large:disabled:hover,
.btn-flat:disabled:hover,
.btn[disabled]:hover,
[disabled].btn-large:hover,
.btn-floating[disabled]:hover,
.btn-large[disabled]:hover,
.btn-flat[disabled]:hover {
  background-color: #DFDFDF !important;
  color: #9F9F9F !important;
}

.btn, .btn-large,
.btn-floating,
.btn-large,
.btn-flat {
  font-size: 1rem;
  outline: 0;
}

.btn i, .btn-large i,
.btn-floating i,
.btn-large i,
.btn-flat i {
  font-size: 1.3rem;
  line-height: inherit;
}

.btn:focus, .btn-large:focus,
.btn-floating:focus {
  background-color: #1d7d74;
}

.btn, .btn-large {
  text-decoration: none;
  color: #fff;
  background-color: #232f3e;
  text-align: center;
  letter-spacing: .5px;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
  cursor: pointer;
}

.btn:hover, .btn-large:hover {
  background-color: #2bbbad;
}

.btn-floating {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  line-height:40px;
  padding: 0;
  background-color: #26a69a;
  border-radius: 50%;
  -webkit-transition: .3s;
  transition: .3s;
  cursor: pointer;
  vertical-align: middle;
}

.btn-floating:hover {
  background-color: #26a69a;
}

.btn-floating:before {
  border-radius: 0;
}

.btn-floating.btn-large {
  width: 56px;
  height: 56px;
}

.btn-floating.btn-large.halfway-fab {
  bottom: -28px;
}

.btn-floating.btn-large i {
  line-height: 56px;
}

.btn-floating.halfway-fab {
  position: absolute;
  right: 24px;
  bottom: -20px;
}

.btn-floating.halfway-fab.left {
  right: auto;
  left: 24px;
}

.btn-floating i {
  width: inherit;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 1.6rem;
  line-height:56px;
}

button.btn-floating {
  border: none;
}


.btn-flat {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  color: #343434;
  cursor: pointer;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
}

.btn-flat:focus, .btn-flat:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-flat:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

.btn-flat.disabled {
  background-color: transparent !important;
  color: #b3b2b2 !important;
  cursor: default;
}

.btn-large {
  height: 54px;
  line-height: 54px;
}

.btn-large i {
  font-size: 1.6rem;
}

.btn-block {
  display: block;
}

.dropdown-content {
  background-color: #fff;
  margin: 0;
  display: none;
  min-width: 100px;
  max-height: 650px;
  overflow-y: auto;
  opacity: 0;
  position: absolute;
  z-index: 999;
  will-change: width, height;
}

.dropdown-content li {
  clear: both;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  min-height: 50px;
  line-height: 1.5rem;
  width: 100%;
  text-align: left;
  text-transform: none;
}

.dropdown-content li:hover, .dropdown-content li.active, .dropdown-content li.selected {
  background-color: #eee;
}

.dropdown-content li.active.selected {
  background-color: #e1e1e1;
}

.dropdown-content li.divider {
  min-height: 0;
  height: 1px;
}

.dropdown-content li > a, .dropdown-content li > span {
  font-size: 16px;
  color: #26a69a;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}

.dropdown-content li > span > label {
  top: 1px;
  left: 0;
  height: 18px;
}

.dropdown-content li > a > i {
  height: inherit;
  line-height: inherit;
  float: left;
  margin: 0 24px 0 0;
  width: 24px;
}

.input-field.col .dropdown-content [type="checkbox"] + label {
  top: 1px;
  left: 0;
  height: 18px;
}


.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  -webkit-transition: .3s ease-out;
  transition: .3s ease-out;
}


.waves-effect input[type="button"], .waves-effect input[type="reset"], .waves-effect input[type="submit"] {
  border: 0;
  font-style: normal;
  font-size: inherit;
  text-transform: inherit;
  background: none;
}

.waves-effect img {
  position: relative;
  z-index: -1;
}

.waves-notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

.waves-circle {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-block {
  display: block;
}

.waves-effect .waves-ripple {
  z-index: -1;
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  will-change: top, opacity;
  transform: scaleX(1)!important;

}

@media only screen and (max-width: 992px) {
  .modal {
    width: 80%;
  }
}

.modal h1, .modal h2, .modal h3, .modal h4 {
  margin-top: 0;
}

.modal .modal-content {
  padding: 24px;
}

.modal .modal-close {
  cursor: pointer;
}

.modal .modal-footer {
  border-radius: 0 0 2px 2px;
  background-color: #fafafa;
  padding: 4px 30px;
  min-height: 56px;
  width: 100%;
  text-align: right;
}

.modal .modal-footer .btn, .modal .modal-footer .btn-large, .modal .modal-footer .btn-flat {
  margin: 6px 0;
}

.modal-overlay {
  position: fixed;
  z-index: 999;
  top: -25%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #000;
  display: none;
  will-change: opacity;
}

.modal.modal-fixed-footer {
  padding: 0;
  height: 70%;
}

.modal.modal-fixed-footer .modal-content {
  position: absolute;
  height: calc(100% - 56px);
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
}

.modal.modal-fixed-footer .modal-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
}

.modal.bottom-sheet {
  top: auto;
  bottom: -100%;
  margin: 0;
  width: 100%;
  max-height: 45%;
  border-radius: 0;
  will-change: bottom, opacity;
}

.collapsible {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  margin: 0.5rem 0 1rem 0;
}

.collapsible-header {
 
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.5;
  padding: 1rem;
  background-color: #1565C0;
  border-bottom: 1px solid #ddd;
  color: #fff;
  border-radius: 4px;

}

.collapsible-header i {
 float:right;
}

.collapsible-body {
  display: none;
  border-bottom: 1px solid #ddd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1rem;
  background: #fff;
}

.side-nav .collapsible,
.side-nav.fixed .collapsible {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.side-nav .collapsible li,
.side-nav.fixed .collapsible li {
  padding: 0;
}

.side-nav .collapsible-header,
.side-nav.fixed .collapsible-header {
  background-color: transparent;
  border: none;
  line-height: inherit;
  height: inherit;
  padding: 0 16px;
}

.side-nav .collapsible-header:hover,
.side-nav.fixed .collapsible-header:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.side-nav .collapsible-header i,
.side-nav.fixed .collapsible-header i {
  line-height: inherit;
}

.side-nav .collapsible-body,
.side-nav.fixed .collapsible-body {
  border: 0;
  background-color: #fff;
}

.side-nav .collapsible-body li a,
.side-nav.fixed .collapsible-body li a {
  padding: 0 23.5px 0 31px;
}

.collapsible.popout {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.collapsible.popout > li {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 0 24px;
  -webkit-transition: margin 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: margin 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.collapsible.popout > li.active {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  margin: 16px 0;
}


select:focus {
  outline: 1px solid #c9f3ef;
}

button:focus {
  outline: none;
  background-color: #2ab7a9;
}

label {
  font-size: 0.8rem;
  color: #9e9e9e;
}

::-webkit-input-placeholder {
  color: #d1d1d1;
}
::-moz-placeholder {
  color: #d1d1d1;
}
:-ms-input-placeholder {
  color: #d1d1d1;
}
::placeholder {
  color: #d1d1d1;
}

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 2.5rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 0 20px 0;
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input:not([type]):disabled, input:not([type])[readonly="readonly"],
input[type=text]:not(.browser-default):disabled,
input[type=text]:not(.browser-default)[readonly="readonly"],
input[type=password]:not(.browser-default):disabled,
input[type=password]:not(.browser-default)[readonly="readonly"],
input[type=email]:not(.browser-default):disabled,
input[type=email]:not(.browser-default)[readonly="readonly"],
input[type=url]:not(.browser-default):disabled,
input[type=url]:not(.browser-default)[readonly="readonly"],
input[type=time]:not(.browser-default):disabled,
input[type=time]:not(.browser-default)[readonly="readonly"],
input[type=date]:not(.browser-default):disabled,
input[type=date]:not(.browser-default)[readonly="readonly"],
input[type=datetime]:not(.browser-default):disabled,
input[type=datetime]:not(.browser-default)[readonly="readonly"],
input[type=datetime-local]:not(.browser-default):disabled,
input[type=datetime-local]:not(.browser-default)[readonly="readonly"],
input[type=tel]:not(.browser-default):disabled,
input[type=tel]:not(.browser-default)[readonly="readonly"],
input[type=number]:not(.browser-default):disabled,
input[type=number]:not(.browser-default)[readonly="readonly"],
input[type=search]:not(.browser-default):disabled,
input[type=search]:not(.browser-default)[readonly="readonly"],
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly="readonly"] {
  color: rgba(0, 0, 0, 0.42);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
}

input:not([type]):disabled + label,
input:not([type])[readonly="readonly"] + label,
input[type=text]:not(.browser-default):disabled + label,
input[type=text]:not(.browser-default)[readonly="readonly"] + label,
input[type=password]:not(.browser-default):disabled + label,
input[type=password]:not(.browser-default)[readonly="readonly"] + label,
input[type=email]:not(.browser-default):disabled + label,
input[type=email]:not(.browser-default)[readonly="readonly"] + label,
input[type=url]:not(.browser-default):disabled + label,
input[type=url]:not(.browser-default)[readonly="readonly"] + label,
input[type=time]:not(.browser-default):disabled + label,
input[type=time]:not(.browser-default)[readonly="readonly"] + label,
input[type=date]:not(.browser-default):disabled + label,
input[type=date]:not(.browser-default)[readonly="readonly"] + label,
input[type=datetime]:not(.browser-default):disabled + label,
input[type=datetime]:not(.browser-default)[readonly="readonly"] + label,
input[type=datetime-local]:not(.browser-default):disabled + label,
input[type=datetime-local]:not(.browser-default)[readonly="readonly"] + label,
input[type=tel]:not(.browser-default):disabled + label,
input[type=tel]:not(.browser-default)[readonly="readonly"] + label,
input[type=number]:not(.browser-default):disabled + label,
input[type=number]:not(.browser-default)[readonly="readonly"] + label,
input[type=search]:not(.browser-default):disabled + label,
input[type=search]:not(.browser-default)[readonly="readonly"] + label,
textarea.materialize-textarea:disabled + label,
textarea.materialize-textarea[readonly="readonly"] + label {
  color: rgba(0, 0, 0, 0.42);
}



input:not([type]).validate + label,
input[type=text]:not(.browser-default).validate + label,
input[type=password]:not(.browser-default).validate + label,
input[type=email]:not(.browser-default).validate + label,
input[type=url]:not(.browser-default).validate + label,
input[type=time]:not(.browser-default).validate + label,
input[type=date]:not(.browser-default).validate + label,
input[type=datetime]:not(.browser-default).validate + label,
input[type=datetime-local]:not(.browser-default).validate + label,
input[type=tel]:not(.browser-default).validate + label,
input[type=number]:not(.browser-default).validate + label,
input[type=search]:not(.browser-default).validate + label,
textarea.materialize-textarea.validate + label {
  width: 100%;
}

input:not([type]).invalid + label:after,
input:not([type]).valid + label:after,
input[type=text]:not(.browser-default).invalid + label:after,
input[type=text]:not(.browser-default).valid + label:after,
input[type=password]:not(.browser-default).invalid + label:after,
input[type=password]:not(.browser-default).valid + label:after,
input[type=email]:not(.browser-default).invalid + label:after,
input[type=email]:not(.browser-default).valid + label:after,
input[type=url]:not(.browser-default).invalid + label:after,
input[type=url]:not(.browser-default).valid + label:after,
input[type=time]:not(.browser-default).invalid + label:after,
input[type=time]:not(.browser-default).valid + label:after,
input[type=date]:not(.browser-default).invalid + label:after,
input[type=date]:not(.browser-default).valid + label:after,
input[type=datetime]:not(.browser-default).invalid + label:after,
input[type=datetime]:not(.browser-default).valid + label:after,
input[type=datetime-local]:not(.browser-default).invalid + label:after,
input[type=datetime-local]:not(.browser-default).valid + label:after,
input[type=tel]:not(.browser-default).invalid + label:after,
input[type=tel]:not(.browser-default).valid + label:after,
input[type=number]:not(.browser-default).invalid + label:after,
input[type=number]:not(.browser-default).valid + label:after,
input[type=search]:not(.browser-default).invalid + label:after,
input[type=search]:not(.browser-default).valid + label:after,
textarea.materialize-textarea.invalid + label:after,
textarea.materialize-textarea.valid + label:after {
  display: none;
}

input:not([type]).invalid + label.active:after,
input:not([type]).valid + label.active:after,
input[type=text]:not(.browser-default).invalid + label.active:after,
input[type=text]:not(.browser-default).valid + label.active:after,
input[type=password]:not(.browser-default).invalid + label.active:after,
input[type=password]:not(.browser-default).valid + label.active:after,
input[type=email]:not(.browser-default).invalid + label.active:after,
input[type=email]:not(.browser-default).valid + label.active:after,
input[type=url]:not(.browser-default).invalid + label.active:after,
input[type=url]:not(.browser-default).valid + label.active:after,
input[type=time]:not(.browser-default).invalid + label.active:after,
input[type=time]:not(.browser-default).valid + label.active:after,
input[type=date]:not(.browser-default).invalid + label.active:after,
input[type=date]:not(.browser-default).valid + label.active:after,
input[type=datetime]:not(.browser-default).invalid + label.active:after,
input[type=datetime]:not(.browser-default).valid + label.active:after,
input[type=datetime-local]:not(.browser-default).invalid + label.active:after,
input[type=datetime-local]:not(.browser-default).valid + label.active:after,
input[type=tel]:not(.browser-default).invalid + label.active:after,
input[type=tel]:not(.browser-default).valid + label.active:after,
input[type=number]:not(.browser-default).invalid + label.active:after,
input[type=number]:not(.browser-default).valid + label.active:after,
input[type=search]:not(.browser-default).invalid + label.active:after,
input[type=search]:not(.browser-default).valid + label.active:after,
textarea.materialize-textarea.invalid + label.active:after,
textarea.materialize-textarea.valid + label.active:after {
  display: block;
}

input.valid:not([type]), input.valid:not([type]):focus,
input[type=text].valid:not(.browser-default),
input[type=text].valid:not(.browser-default):focus,
input[type=password].valid:not(.browser-default),
input[type=password].valid:not(.browser-default):focus,
input[type=email].valid:not(.browser-default),
input[type=email].valid:not(.browser-default):focus,
input[type=url].valid:not(.browser-default),
input[type=url].valid:not(.browser-default):focus,
input[type=time].valid:not(.browser-default),
input[type=time].valid:not(.browser-default):focus,
input[type=date].valid:not(.browser-default),
input[type=date].valid:not(.browser-default):focus,
input[type=datetime].valid:not(.browser-default),
input[type=datetime].valid:not(.browser-default):focus,
input[type=datetime-local].valid:not(.browser-default),
input[type=datetime-local].valid:not(.browser-default):focus,
input[type=tel].valid:not(.browser-default),
input[type=tel].valid:not(.browser-default):focus,
input[type=number].valid:not(.browser-default),
input[type=number].valid:not(.browser-default):focus,
input[type=search].valid:not(.browser-default),
input[type=search].valid:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus, .select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #4CAF50;
 
}

input.invalid:not([type]), input.invalid:not([type]):focus,
input[type=text].invalid:not(.browser-default),
input[type=text].invalid:not(.browser-default):focus,
input[type=password].invalid:not(.browser-default),
input[type=password].invalid:not(.browser-default):focus,
input[type=email].invalid:not(.browser-default),
input[type=email].invalid:not(.browser-default):focus,
input[type=url].invalid:not(.browser-default),
input[type=url].invalid:not(.browser-default):focus,
input[type=time].invalid:not(.browser-default),
input[type=time].invalid:not(.browser-default):focus,
input[type=date].invalid:not(.browser-default),
input[type=date].invalid:not(.browser-default):focus,
input[type=datetime].invalid:not(.browser-default),
input[type=datetime].invalid:not(.browser-default):focus,
input[type=datetime-local].invalid:not(.browser-default),
input[type=datetime-local].invalid:not(.browser-default):focus,
input[type=tel].invalid:not(.browser-default),
input[type=tel].invalid:not(.browser-default):focus,
input[type=number].invalid:not(.browser-default),
input[type=number].invalid:not(.browser-default):focus,
input[type=search].invalid:not(.browser-default),
input[type=search].invalid:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus, .select-wrapper.invalid > input.select-dropdown {
  border-bottom: 1px solid #F44336;
}

input:not([type]).valid + label:after,
input:not([type]):focus.valid + label:after,
input[type=text]:not(.browser-default).valid + label:after,
input[type=text]:not(.browser-default):focus.valid + label:after,
input[type=password]:not(.browser-default).valid + label:after,
input[type=password]:not(.browser-default):focus.valid + label:after,
input[type=email]:not(.browser-default).valid + label:after,
input[type=email]:not(.browser-default):focus.valid + label:after,
input[type=url]:not(.browser-default).valid + label:after,
input[type=url]:not(.browser-default):focus.valid + label:after,
input[type=time]:not(.browser-default).valid + label:after,
input[type=time]:not(.browser-default):focus.valid + label:after,
input[type=date]:not(.browser-default).valid + label:after,
input[type=date]:not(.browser-default):focus.valid + label:after,
input[type=datetime]:not(.browser-default).valid + label:after,
input[type=datetime]:not(.browser-default):focus.valid + label:after,
input[type=datetime-local]:not(.browser-default).valid + label:after,
input[type=datetime-local]:not(.browser-default):focus.valid + label:after,
input[type=tel]:not(.browser-default).valid + label:after,
input[type=tel]:not(.browser-default):focus.valid + label:after,
input[type=number]:not(.browser-default).valid + label:after,
input[type=number]:not(.browser-default):focus.valid + label:after,
input[type=search]:not(.browser-default).valid + label:after,
input[type=search]:not(.browser-default):focus.valid + label:after,
textarea.materialize-textarea.valid + label:after,
textarea.materialize-textarea:focus.valid + label:after, .select-wrapper.valid + label:after {
  content: attr(data-success);
  color: #4CAF50;
  opacity: 1;
  -webkit-transform: translateY(9px);
          transform: translateY(9px);
}

input:not([type]).invalid + label:after,
input:not([type]):focus.invalid + label:after,
input[type=text]:not(.browser-default).invalid + label:after,
input[type=text]:not(.browser-default):focus.invalid + label:after,
input[type=password]:not(.browser-default).invalid + label:after,
input[type=password]:not(.browser-default):focus.invalid + label:after,
input[type=email]:not(.browser-default).invalid + label:after,
input[type=email]:not(.browser-default):focus.invalid + label:after,
input[type=url]:not(.browser-default).invalid + label:after,
input[type=url]:not(.browser-default):focus.invalid + label:after,
input[type=time]:not(.browser-default).invalid + label:after,
input[type=time]:not(.browser-default):focus.invalid + label:after,
input[type=date]:not(.browser-default).invalid + label:after,
input[type=date]:not(.browser-default):focus.invalid + label:after,
input[type=datetime]:not(.browser-default).invalid + label:after,
input[type=datetime]:not(.browser-default):focus.invalid + label:after,
input[type=datetime-local]:not(.browser-default).invalid + label:after,
input[type=datetime-local]:not(.browser-default):focus.invalid + label:after,
input[type=tel]:not(.browser-default).invalid + label:after,
input[type=tel]:not(.browser-default):focus.invalid + label:after,
input[type=number]:not(.browser-default).invalid + label:after,
input[type=number]:not(.browser-default):focus.invalid + label:after,
input[type=search]:not(.browser-default).invalid + label:after,
input[type=search]:not(.browser-default):focus.invalid + label:after,
textarea.materialize-textarea.invalid + label:after,
textarea.materialize-textarea:focus.invalid + label:after, .select-wrapper.invalid + label:after {
  content: attr(data-error);
  color: #F44336;
  opacity: 1;
  -webkit-transform: translateY(9px);
          transform: translateY(9px);
}

input:not([type]) + label:after,
input[type=text]:not(.browser-default) + label:after,
input[type=password]:not(.browser-default) + label:after,
input[type=email]:not(.browser-default) + label:after,
input[type=url]:not(.browser-default) + label:after,
input[type=time]:not(.browser-default) + label:after,
input[type=date]:not(.browser-default) + label:after,
input[type=datetime]:not(.browser-default) + label:after,
input[type=datetime-local]:not(.browser-default) + label:after,
input[type=tel]:not(.browser-default) + label:after,
input[type=number]:not(.browser-default) + label:after,
input[type=search]:not(.browser-default) + label:after,
textarea.materialize-textarea + label:after, .select-wrapper + label:after {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  -webkit-transition: .2s opacity ease-out, .2s color ease-out;
  transition: .2s opacity ease-out, .2s color ease-out;
}

.input-field {
  position: relative;
  margin-top: 1rem;
}

.input-field.inline {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.input-field.inline input,
.input-field.inline .select-dropdown {
  margin-bottom: 1rem;
}

.input-field.col label {
  left: 0.75rem;
}

.input-field.col .prefix ~ label,
.input-field.col .prefix ~ .validate ~ label {
  width: calc(100% - 3rem - 1.5rem);
}

.input-field label {
  color: #9e9e9e;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  font-size: 1rem;
  cursor: text;
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .2s ease-out;
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  text-align: initial;
  -webkit-transform: translateY(12px);
          transform: translateY(12px);
  pointer-events: none;
}

.input-field label:not(.label-icon).active {
  -webkit-transform: translateY(-14px) scale(0.8);
          transform: translateY(-14px) scale(0.8);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}

.input-field .prefix {
  position: absolute;
  width: 3rem;
  font-size: 2rem;
  -webkit-transition: color .2s;
  transition: color .2s;
}

.input-field .prefix.active {
  color: #26a69a;
}

.input-field .prefix ~ input,
.input-field .prefix ~ textarea,
.input-field .prefix ~ label,
.input-field .prefix ~ .validate ~ label,
.input-field .prefix ~ .autocomplete-content {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.input-field .prefix ~ label {
  margin-left: 3rem;
}

@media only screen and (max-width: 992px) {
  .input-field .prefix ~ input {
    width: 86%;
    width: calc(100% - 3rem);
  }
}

@media only screen and (max-width: 600px) {
  .input-field .prefix ~ input {
    width: 80%;
    width: calc(100% - 3rem);
  }
}

/* Search Field */
.input-field input[type=search] {
  display: block;
  line-height: inherit;
}

.nav-wrapper .input-field input[type=search] {
  height: inherit;
  padding-left: 4rem;
  width: calc(100% - 4rem);
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.input-field input[type=search]:focus {
  background-color: #fff;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #444;
}

.input-field input[type=search]:focus + label i,
.input-field input[type=search]:focus ~ .mdi-navigation-close,
.input-field input[type=search]:focus ~ .material-icons {
  color: #444;
}

.input-field input[type=search] + label {
  left: 1rem;
}

.input-field input[type=search] ~ .mdi-navigation-close,
.input-field input[type=search] ~ .material-icons {
  position: absolute;
  top: 0;
  right: 1rem;
  color: transparent;
  cursor: pointer;
  font-size: 2rem;
  -webkit-transition: .3s color;
  transition: .3s color;
}

textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent;
}

textarea.materialize-textarea {
  overflow-y: hidden;
  padding: .8rem 0 1.6rem 0;
  resize: none;
  min-height: 3rem;
}

textarea.materialize-textarea.validate + label {
  height: 100%;
}

textarea.materialize-textarea.validate + label::after {
  top: calc(100% - 12px);
}

textarea.materialize-textarea.validate + label:not(.label-icon).active {
  -webkit-transform: translateY(-25px);
          transform: translateY(-25px);
}

.hiddendiv {
  display: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding-top: 1.2rem;
  position: absolute;
  top: 0;
}

.autocomplete-content {
  margin-top: -20px;
  margin-bottom: 20px;
  display: block;
  opacity: 1;
  position: static;
}

.autocomplete-content li .highlight {
  color: #444;
}

.autocomplete-content li img {
  height: 40px;
  width: 40px;
  margin: 5px 15px;
}


[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="radio"]:not(:checked) + label:not(.review),
[type="radio"]:checked + label:not(.review) {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-transition: .28s ease;
  transition: .28s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

[type="radio"] + label:before:not(.review),
[type="radio"] + label:after:not(.review) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: .28s ease;
  transition: .28s ease;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%;
}

[type="radio"]:not(:checked) + label:before:not(.review),
[type="radio"]:not(:checked) + label:after:not(.review) {
  border: 2px solid #5a5a5a;
}

[type="radio"]:not(:checked) + label:after {
  -webkit-transform: scale(0);
          transform: scale(0);
}

[type="radio"]:checked + label:before {
  border: 2px solid transparent;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 2px solid #26a69a;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #26a69a;
}

[type="radio"]:checked + label:after {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

[type="radio"].with-gap:checked + label:after {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

[type="radio"].tabbed:focus + label:before {
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}

[type="radio"].with-gap:disabled:checked + label:before{
  border: 2px solid rgba(0, 0, 0, 0.42);
}

[type="radio"].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.42);
}

[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.42);
}

[type="radio"]:disabled + label {
  color: rgba(0, 0, 0, 0.42);
}

[type="radio"]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.42);
}

[type="radio"]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.42);
  border-color: #949494;
}

form p {
  margin-bottom: 10px;
  text-align: left;
}

form p:last-child {
  margin-bottom: 0;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}



[type="checkbox"] + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 2px;
  -webkit-transition: .2s;
  transition: .2s;
}

[type="checkbox"]:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

[type="checkbox"]:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.42);
}

[type="checkbox"].tabbed:focus + label:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  border: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"]:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

[type="checkbox"]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"]:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  background-color: transparent;
}

[type="checkbox"].filled-in + label:after {
  border-radius: 2px;
}

[type="checkbox"].filled-in + label:before,
[type="checkbox"].filled-in + label:after {
  content: '';
  left: 0;
  position: absolute;
  -webkit-transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1;
}

[type="checkbox"].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
          transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0px;
  z-index: 0;
}

[type="checkbox"].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
          transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0;
}

[type="checkbox"].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a;
}

[type="checkbox"].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type="checkbox"].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #949494;
}

[type="checkbox"].filled-in:disabled:checked + label:before {
  background-color: transparent;
}

[type="checkbox"].filled-in:disabled:checked + label:after {
  background-color: #949494;
  border-color: #949494;
}


.switch,
.switch * {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.switch label {
  cursor: pointer;
}

.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label input[type=checkbox]:checked + .lever {
  background-color: #84c7c1;
}

.switch label input[type=checkbox]:checked + .lever:before, .switch label input[type=checkbox]:checked + .lever:after {
  left: 18px;
}

.switch label input[type=checkbox]:checked + .lever:after {
  background-color: #26a69a;
}

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 36px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 15px;
  margin-right: 10px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}

.switch label .lever:before, .switch label .lever:after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  top: -3px;
  -webkit-transition: left 0.3s ease, background .3s ease, -webkit-box-shadow 0.1s ease, -webkit-transform .1s ease;
  transition: left 0.3s ease, background .3s ease, -webkit-box-shadow 0.1s ease, -webkit-transform .1s ease;
  transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease, transform .1s ease;
  transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease, transform .1s ease, -webkit-box-shadow 0.1s ease, -webkit-transform .1s ease;
}

.switch label .lever:before {
  background-color: rgba(38, 166, 154, 0.15);
}

.switch label .lever:after {
  background-color: #F1F1F1;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

input[type=checkbox]:checked:not(:disabled) ~ .lever:active::before,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::before {
  -webkit-transform: scale(2.4);
          transform: scale(2.4);
  background-color: rgba(38, 166, 154, 0.15);
}

input[type=checkbox]:not(:disabled) ~ .lever:active:before,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::before {
  -webkit-transform: scale(2.4);
          transform: scale(2.4);
  background-color: rgba(0, 0, 0, 0.08);
}

.switch input[type=checkbox][disabled] + .lever {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.12);
}

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
  background-color: #949494;
}


select {
  display: none;
}

select.browser-default {
  display: block;
}

select {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 3rem;
}

.input-field > select {
  display: block;
  position: absolute;
  width: 0;
  pointer-events: none;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
}

.select-label {
  position: absolute;
}

.select-wrapper {
  position: relative;
}

.select-wrapper.valid + label,
.select-wrapper.invalid + label {
  width: 100%;
  pointer-events: none;
}

.select-wrapper input.select-dropdown {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin: 0 0 20px 0;
  padding: 0;
  display: block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.select-wrapper span.caret {
  color: initial;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 10px;
  margin: auto 0;
  font-size: 10px;
  line-height: 10px;
}

.select-wrapper + label {
  position: absolute;
  top: -26px;
  font-size: 0.8rem;
}

select:disabled {
  color: rgba(0, 0, 0, 0.42);
}

.select-wrapper.disabled span.caret,
.select-wrapper.disabled + label {
  color: rgba(0, 0, 0, 0.42);
}

.select-wrapper input.select-dropdown:disabled {
  color: rgba(0, 0, 0, 0.42);
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.select-wrapper i {
  color: rgba(0, 0, 0, 0.3);
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

.select-dropdown.dropdown-content li.active {
  background-color: transparent;
}

.select-dropdown.dropdown-content li:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.select-dropdown.dropdown-content li.selected {
  background-color: rgba(0, 0, 0, 0.03);
}

.prefix ~ .select-wrapper {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.prefix ~ label {
  margin-left: 3rem;
}

.select-dropdown li img {
  height: 40px;
  width: 40px;
  margin: 5px 15px;
  float: right;
}

.select-dropdown li.optgroup {
  border-top: 1px solid #eee;
}

.select-dropdown li.optgroup.selected > span {
  color: rgba(0, 0, 0, 0.7);
}

.select-dropdown li.optgroup > span {
  color: rgba(0, 0, 0, 0.4);
}

.select-dropdown li.optgroup ~ li.optgroup-option {
  padding-left: 1rem;
}

/* File Input
   ========================================================================== */
.file-field {
  position: relative;
}

.file-field .file-path-wrapper {
  overflow: hidden;
  padding-left: 10px;
}

.file-field input.file-path {
  width: 100%;
}

.file-field .btn, .file-field .btn-large {
  float: left;
  height: 2.5rem;
    line-height: 2.5rem;
}

.file-field span {
  cursor: pointer;
}




.table-of-contents.fixed {
  position: fixed;
}

.table-of-contents li {
  padding: 2px 0;
}

.table-of-contents a {
  display: inline-block;
  font-weight: 300;
  color: #757575;
  padding-left: 20px;
  height: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: .4;
  display: inline-block;
}

.table-of-contents a:hover {
  color: #a8a8a8;
  padding-left: 19px;
  border-left: 1px solid #ee6e73;
}

.table-of-contents a.active {
  font-weight: 500;
  padding-left: 18px;
  border-left: 2px solid #ee6e73;
}

.side-nav {
  position: fixed;
  width: 300px;
  left: 0;
  top: 0;
  margin: 0;
  z-index: 9999!important;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  height: 100%;
  height: calc(100% + 60px);
  height: -moz-calc(100%);
  padding-bottom: 60px;
  background-color: #fff;
  z-index: 9999 !important;
  overflow-y: auto;
  will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateX(-105%);
          transform: translateX(-105%);
}

.side-nav.right-aligned {
  right: 0;
  -webkit-transform: translateX(105%);
          transform: translateX(105%);
  left: auto;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.side-nav .collapsible {
  margin: 0;
}

.side-nav li {
  float: none;
  line-height: 48px;
}

.side-nav li.active {
  background-color: rgba(0, 0, 0, 0.05);
}

.side-nav li > a {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
}

.side-nav li > a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.side-nav li > a.btn, .side-nav li > a.btn-large, .side-nav li > a.btn-large, .side-nav li > a.btn-flat, .side-nav li > a.btn-floating {
  margin: 10px 15px;
}

.side-nav li > a.btn, .side-nav li > a.btn-large, .side-nav li > a.btn-large, .side-nav li > a.btn-floating {
  color: #fff;
}

.side-nav li > a.btn-flat {
  color: #343434;
}

.side-nav li > a.btn:hover, .side-nav li > a.btn-large:hover, .side-nav li > a.btn-large:hover {
  background-color: #2bbbad;
}

.side-nav li > a.btn-floating:hover {
  background-color: #26a69a;
}

.side-nav li > a > i,
.side-nav li > a > [class^="mdi-"], .side-nav li > a li > a > [class*="mdi-"],
.side-nav li > a > i.material-icons {
  float: left;
  height: 48px;
  line-height: 48px;
  margin: 0 32px 0 0;
  width: 24px;
  color: rgba(0, 0, 0, 0.54);
}

.side-nav .divider {
  margin: 8px 0 0 0;
}

.side-nav .subheader {
  cursor: initial;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
}

.side-nav .subheader:hover {
  background-color: transparent;
}

.side-nav .user-view,
.side-nav .userView {
  position: relative;
  padding: 32px 32px 0;
  margin-bottom: 8px;
}

.side-nav .user-view > a,
.side-nav .userView > a {
  height: auto;
  padding: 0;
}

.side-nav .user-view > a:hover,
.side-nav .userView > a:hover {
  background-color: transparent;
}

.side-nav .user-view .background,
.side-nav .userView .background {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.side-nav .user-view .circle, .side-nav .user-view .name, .side-nav .user-view .email,
.side-nav .userView .circle,
.side-nav .userView .name,
.side-nav .userView .email {
  display: block;
}

.side-nav .user-view .circle,
.side-nav .userView .circle {
  height: 64px;
  width: 64px;
}

.side-nav .user-view .name,
.side-nav .user-view .email,
.side-nav .userView .name,
.side-nav .userView .email {
  font-size: 14px;
  line-height: 24px;
}

.side-nav .user-view .name,
.side-nav .userView .name {
  margin-top: 16px;
  font-weight: 500;
}

.side-nav .user-view .email,
.side-nav .userView .email {
  padding-bottom: 16px;
  font-weight: 400;
}

.drag-target {
  height: 100%;
  width: 10px;
  position: fixed;
  top: 0;
  z-index: 998;
}

.side-nav.fixed {
  left: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  position: fixed;
}

.side-nav.fixed.right-aligned {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 992px) {
  .side-nav.fixed {
    -webkit-transform: translateX(-105%);
            transform: translateX(-105%);
  }
  .side-nav.fixed.right-aligned {
    -webkit-transform: translateX(105%);
            transform: translateX(105%);
  }
  .side-nav a:not(.btn):not(.btn-floating) {
    padding: 0 16px;
  }
  .side-nav .user-view,
  .side-nav .userView {
    padding: 16px 16px 0;
  }
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active,
.side-nav.fixed .collapsible-body > ul:not(.collapsible) > li.active {
  background-color: #ee6e73;
}

.side-nav .collapsible-body > ul:not(.collapsible) > li.active a,
.side-nav.fixed .collapsible-body > ul:not(.collapsible) > li.active a {
  color: #fff;
}

.side-nav .collapsible-body {
  padding: 0;
}

#sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  will-change: opacity;
}

.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}

.preloader-wrapper.small {
  width: 36px;
  height: 36px;
}

.preloader-wrapper.big {
  width: 64px;
  height: 64px;
}

.preloader-wrapper.active {
  -webkit-animation: container-rotate 1568ms linear infinite;
  animation: container-rotate 1568ms linear infinite;
}

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #4caf50;
}

.spinner-blue,
.spinner-blue-only {
  border-color: #4285f4;
}

.spinner-red,
.spinner-red-only {
  border-color: #db4437;
}

.spinner-yellow,
.spinner-yellow-only {
  border-color: #f4b400;
}

.spinner-green,
.spinner-green-only {
  border-color: #0f9d58;
}


.active .spinner-layer.spinner-blue {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-red {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-yellow {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-green {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .spinner-layer,
.active .spinner-layer.spinner-blue-only,
.active .spinner-layer.spinner-red-only,
.active .spinner-layer.spinner-yellow-only,
.active .spinner-layer.spinner-green-only {
  opacity: 1;
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
  }
}

@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
}

@-webkit-keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blue-fade-in-out {
  from {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}

@keyframes red-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
}

@-webkit-keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}

@keyframes yellow-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
}

@-webkit-keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes green-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.gap-patch .circle {
  width: 1000%;
  left: -450%;
}

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 5px;
  /* STROKEWIDTH */
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  -webkit-transform: rotate(129deg);
  transform: rotate(129deg);
}

.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  -webkit-transform: rotate(-129deg);
  transform: rotate(-129deg);
}

.active .circle-clipper.left .circle {
  /* duration: ARCTIME */
  -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.active .circle-clipper.right .circle {
  /* duration: ARCTIME */
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
  }
}

@keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg);
  }
}

@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
  }
}

@keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg);
  }
}

#spinnerContainer.cooldown {
  -webkit-animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
  animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slider {
  position: relative;
  /*height: 400px;*/
  width: 100%;
}

.slider.fullscreen {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.slider.fullscreen ul.slides {
  height: 100%;
}

.slider.fullscreen ul.indicators {
  z-index: 2;
  bottom: 30px;
}

.slider .slides {
  margin: 0;
  height: auto;
}

.slider .slides li {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: inherit;
  overflow: hidden;
}

.slider .slides li img {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;

 /* background-size: contain;
  background-position: center;*/
 /* background-size: 100% auto;
    background-repeat: no-repeat;*/
}

.slider .slides li .caption {
  color: #fff;
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  opacity: 0;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}

.slider .slides li .caption p {
  color: #e0e0e0;
}

.slider .slides li.active {
  z-index: 2;
}

.slider .indicators {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 12px;
  margin: 0;
}

.slider .indicators .indicator-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin: 0 12px;
  background-color: #e0e0e0;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  border-radius: 50%;
}

.slider .indicators .indicator-item.active {
  background-color: #4CAF50;
}

.picker {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
  color: #000000;
  position: absolute;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}


.picker__input {
  cursor: default;
}

.picker__input.picker__input--active {
  border-color: #0089ec;
}


.picker__holder {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}


.picker__holder,
.picker__frame {
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
}


.picker__holder {
  position: fixed;
  -webkit-transition: background 0.15s ease-out, top 0s 0.15s;
  transition: background 0.15s ease-out, top 0s 0.15s;
  -webkit-backface-visibility: hidden;
}


.picker__frame {
  position: absolute;
  margin: 0 auto;
  min-width: 256px;
  width: 300px;
  max-height: 350px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

@media (min-height: 28.875em) {
  .picker__frame {
    overflow: visible;
    top: auto;
    bottom: -100%;
    max-height: 80%;
  }
}

@media (min-height: 40.125em) {
  .picker__frame {
    margin-bottom: 7.5%;
  }
}


.picker__wrap {
  display: table;
  width: 100%;
  height: 100%;
}

@media (min-height: 28.875em) {
  .picker__wrap {
    display: block;
  }
}


.picker__box {
  background: #ffffff;
  display: table-cell;
  vertical-align: middle;
}

@media (min-height: 28.875em) {
  .picker__box {
    display: block;
    border: 1px solid #777777;
    border-top-color: #898989;
    border-bottom-width: 0;
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24);
    box-shadow: 0 12px 36px 16px rgba(0, 0, 0, 0.24);
  }
}

.picker--opened .picker__holder {
  top: 0;
  background: transparent;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#1E000000,endColorstr=#1E000000)";
  zoom: 1;
  background: rgba(0, 0, 0, 0.32);
  -webkit-transition: background 0.15s ease-out;
  transition: background 0.15s ease-out;
}

.picker--opened .picker__frame {
  top: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

@media (min-height: 35.875em) {
  .picker--opened .picker__frame {
    top: 10%;
    bottom: auto;
  }
}


.picker__input.picker__input--active {
  border-color: #E3F2FD;
}

.picker__frame {
  margin: 0 auto;
  max-width: 325px;
}

@media (min-height: 38.875em) {
  .picker--opened .picker__frame {
    top: 10%;
    bottom: auto;
  }
}

@media only screen and (min-width: 601px) {
  .picker__box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .picker__frame {
    width: 80%;
    max-width: 600px;
  }
}


.picker__box {
  padding: 0;
  border-radius: 2px;
  overflow: hidden;
}


.picker__header {
  text-align: center;
  position: relative;
  margin-top: .75em;
}


.picker__month,
.picker__year {
  display: inline-block;
  margin-left: .25em;
  margin-right: .25em;
}

.picker__select--month,
.picker__select--year {
  height: 2em;
  padding: 0;
  margin-left: .25em;
  margin-right: .25em;
}

.picker__select--month.browser-default {
  display: inline;
  background-color: #FFFFFF;
  width: 40%;
}

.picker__select--year.browser-default {
  display: inline;
  background-color: #FFFFFF;
  width: 26%;
}

.picker__select--month:focus,
.picker__select--year:focus {
  border-color: rgba(0, 0, 0, 0.05);
}

.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  padding: .5em 1.25em;
  width: 1em;
  height: 1em;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  top: -0.25em;
}

.picker__nav--prev {
  left: -1em;
  padding-right: 1.25em;
}

.picker__nav--next {
  right: -1em;
  padding-left: 1.25em;
}

.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #f5f5f5;
  border-left-color: #f5f5f5;
}


.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: 1rem;
  width: 100%;
  margin-top: .75em;
  margin-bottom: .5em;
}

.picker__table th, .picker__table td {
  text-align: center;
}

.picker__table td {
  margin: 0;
  padding: 0;
}


.picker__weekday {
  width: 14.285714286%;
  font-size: .75em;
  padding-bottom: .25em;
  color: #999999;
  font-weight: 500;
}

@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: .5em;
  }
}


.picker__day--today {
  position: relative;
  color: #595959;
  letter-spacing: -.3;
  padding: .75rem 0;
  font-weight: 400;
  border: 1px solid transparent;
}

.picker__day--disabled:before {
  border-top-color: #aaaaaa;
}

.picker__day--infocus:hover {
  cursor: pointer;
  color: #000;
  font-weight: 500;
}

.picker__day--outfocus {
  display: none;
  padding: .75rem 0;
  color: #fff;
}

.picker__day--outfocus:hover {
  cursor: pointer;
  color: #dddddd;
  font-weight: 500;
}

.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  cursor: pointer;
}

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  border-radius: 50%;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  background: #0089ec;
  color: #ffffff;
}

.picker__day--disabled,
.picker__day--disabled:hover,
.picker--focused .picker__day--disabled {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default;
}

.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
  background: #bbbbbb;
}


.picker__footer {
  text-align: right;
}

.picker__button--today,
.picker__button--clear,
.picker__button--close {
  border: 1px solid #ffffff;
  background: #ffffff;
  font-size: .8em;
  padding: .66em 0;
  font-weight: bold;
  width: 33%;
  display: inline-block;
  vertical-align: bottom;
}

.picker__button--today:hover,
.picker__button--clear:hover,
.picker__button--close:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb;
}

.picker__button--today:focus,
.picker__button--clear:focus,
.picker__button--close:focus {
  background: #b1dcfb;
  border-color: rgba(0, 0, 0, 0.05);
  outline: none;
}

.picker__button--today:before,
.picker__button--clear:before,
.picker__button--close:before {
  position: relative;
  display: inline-block;
  height: 0;
}

.picker__button--today:before,
.picker__button--clear:before {
  content: " ";
  margin-right: .45em;
}

.picker__button--today:before {
  top: -0.05em;
  width: 0;
  border-top: 0.66em solid #0059bc;
  border-left: .66em solid transparent;
}

.picker__button--clear:before {
  top: -0.25em;
  width: .66em;
  border-top: 3px solid #ee2200;
}

.picker__button--close:before {
  content: "\D7";
  top: -0.1em;
  vertical-align: top;
  font-size: 1.1em;
  margin-right: .35em;
  color: #777777;
}

.picker__button--today[disabled],
.picker__button--today[disabled]:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default;
}

.picker__button--today[disabled]:before {
  border-top-color: #aaaaaa;
}


.picker__date-display {
  text-align: left;
  background-color: #26a69a;
  color: #fff;
  padding: 18px;
  font-weight: 300;
}

@media only screen and (min-width: 601px) {
  .picker__date-display {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .picker__weekday-display {
    display: block;
  }
  .picker__container__wrapper {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
        -ms-flex: 2;
            flex: 2;
  }
}

.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  color: #000000;
  background: #a1ded8;
}

.picker__weekday-display {
  font-weight: 500;
  font-size: 2.8rem;
  margin-right: 5px;
  margin-top: 4px;
}

.picker__month-display {
  font-size: 2.8rem;
  font-weight: 500;
}

.picker__day-display {
  font-size: 2.8rem;
  font-weight: 500;
  margin-right: 5px;
}

.picker__year-display {
  font-size: 1.5rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}


.picker__calendar-container {
  padding: 0 1rem;
}

.picker__calendar-container thead {
  border: none;
}

.picker__table {
  margin-top: 0;
  margin-bottom: .5em;
}

.picker__day--infocus {
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: -.3px;
  padding: 0.75rem 0;
  font-weight: 400;
  border: 1px solid transparent;
}

@media only screen and (min-width: 601px) {
  .picker__day--infocus {
    padding: 1.1rem 0;
  }
}

.picker__day.picker__day--today {
  color: #26a69a;
}

.picker__day.picker__day--today.picker__day--selected {
  color: #fff;
}

.picker__weekday {
  font-size: .9rem;
}

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  border-radius: 50%;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  background-color: #26a69a;
  color: #ffffff;
}

.picker__day--selected.picker__day--outfocus,
.picker__day--selected:hover.picker__day--outfocus,
.picker--focused .picker__day--selected.picker__day--outfocus {
  background-color: #a1ded8;
}

.picker__footer {
  text-align: right;
  padding: 5px 10px;
}

.picker__close, .picker__today, .picker__clear {
  font-size: 1.1rem;
  padding: 0 1rem;
  color: #26a69a;
}

.picker__clear {
  color: #f44336;
  float: left;
}

.picker__nav--prev:before,
.picker__nav--next:before {
  content: " ";
  border-top: .5em solid transparent;
  border-bottom: .5em solid transparent;
  border-right: 0.75em solid #676767;
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto;
}

.picker__nav--next:before {
  border-right: 0;
  border-left: 0.75em solid #676767;
}

button.picker__today:focus, button.picker__clear:focus, button.picker__close:focus {
  background-color: #a1ded8;
}

.picker__list {
  list-style: none;
  padding: 0.75em 0 4.2em;
  margin: 0;
}


.picker__list-item {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  margin-bottom: -1px;
  position: relative;
  background: #fff;
  padding: .75em 1.25em;
}

@media (min-height: 46.75em) {
  .picker__list-item {
    padding: .5em 1em;
  }
}

.picker__list-item:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
  border-color: #0089ec;
  z-index: 10;
}

.picker__list-item--highlighted {
  border-color: #0089ec;
  z-index: 10;
}

.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected {
  background: #0089ec;
  color: #fff;
  z-index: 10;
}

.picker__list-item--disabled,
.picker__list-item--disabled:hover,
.picker--focused .picker__list-item--disabled {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #ddd;
  cursor: default;
  border-color: #ddd;
  z-index: auto;
}


.picker--time .picker__button--clear {
  display: block;
  width: 80%;
  margin: 1em auto 0;
  padding: 1em 1.25em;
  background: none;
  border: 0;
  font-weight: 500;
  font-size: .67em;
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
}

.picker--time .picker__button--clear:hover,
.picker--time .picker__button--clear:focus {
  color: #000;
  background: #b1dcfb;
  background: #ee2200;
  border-color: #ee2200;
  cursor: pointer;
  color: #fff;
  outline: none;
}

.picker--time .picker__button--clear:before {
  top: -0.25em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25em;
  font-weight: bold;
}

.picker--time .picker__button--clear:hover:before,
.picker--time .picker__button--clear:focus:before {
  color: #fff;
}

.picker--time .picker__frame {
  min-width: 256px;
  max-width: 320px;
}


.picker--time .picker__box {
  font-size: 1em;
  background: #f2f2f2;
  padding: 0;
}

@media (min-height: 40.125em) {
  .picker--time .picker__box {
    margin-bottom: 5em;
  }
}



.active-filter {
    background: #f44336!important;
    color: #fff!important;
}


.alert{
  margin: 5px 0;
      clear: both;
    color: #fff;
    padding: 3px 5px;
    width: 100%;
        border-radius: 2px;

}
.alert-error,.alert-danger,.alert-error-script{
      background: rgba(255, 0, 0, 0.73);
}
.alert-success{
      /*background: rgba(255, 0, 0, 0.73);*/
      background: #8BC34A;
}
.alert-success-script{background: rgba(76, 175, 80, 0.7);}
.alert button{
      color: #000;
    font-size: 18px;
}.fixed-action-btn{
      position: fixed;
    bottom: 0;
    right: 0;
    margin: 10px;
}
.address-box p{font-weight: bold;}