#category ul.list li img,
ul.offer-zone li a img {
    max-width: 100%;
    width: 100%;
}
html {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.icon-block {
    padding: 0 15px
}
.icon-block .fa {
    font-size: inherit
}
body {
        margin: 0 auto;
    max-width: 900px;
}
body.home {
    background: #f2f2f2;
}
body.profile {
    background: #f2f2f2!important
}
nav {
    box-shadow: none;
    /*background-color: #1565C0;*/
    height: auto;
    background: rgb(3, 57, 108);

}
nav.profile,
nav.search {
        background: #114474!important;

}
nav .go-back,
nav:not(.category) .button-collapse {
    margin-left: 0
}
nav.search .search-wrapper input#search,
nav.search .search-wrapper input.search-box {
    padding: 0 0 0 50px
}
.l-15 {
    left: 15px!important
}
.lh-25 {
    line-height: 25px!important
}
nav.product ul a {
    padding: 0 10px
}
h1.product-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 0
}
nav.product {
    background-color: #fff!important
}
.f24 {
    font-size: 24px
}
.f12 {
    font-size: 12px
}
.f18 {
    font-size: 18px
}
.tx-lig50 {
    color: rgba(193, 198, 202, .9)
}
.tx-pk {
    color: #333
}
p.price {
    margin-top: 10px
}
.product-option {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 0;
    z-index: 998
}
    .tabs .tab a {
        padding: 0 12px;
        text-align: left;
        font-weight: 400;
        font-size: 13px
    }
    /*ul.product-detail li:not(.row){
            line-height: 30px

    }*/
ul.product-detail li.row {
    margin-bottom: 15px!important;
}
.product-next,
.product-previous {
    position: fixed;
    top: 30%;
    line-height: 100%;
    background: #ddd;
    z-index: 99;
    text-align: center
}
h6.pd-title {
    font-weight: 600;
    color: #564f4f
}
#share-on label {
    color: #000
}
.product-next {
    right: 0;
    border: 1px solid #9e9e9e;
    border-radius: 50%
}
.product-previous {
    left: 0;
    border: 1px solid #9e9e9e;
    border-radius: 50%
}
.search-list ul li,
li.review {
    border-bottom: 1px solid #ccc
}
li.review {
    padding: 5px 12px
}
li.review h6 {
    font-weight: 700
}
li.review h6 span i {
    font-size: 100%;
    vertical-align: top
}
li.review h6 span,.review span.star {
    background: green;
    color: #fff;
    padding: 2px 5px;
    border-radius: 3px
}
.review span.star i{
  font-size: 12px;
}
.other-info li {
    line-height: 25px
}
.btn-flat.block {
    width: 100%;
    text-align: center;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    background: #FF9800;
    color: #fff
}
.btn-flat.block.white {
    color: #000;
    background: #fff
}
.divider.bold {
    height: 10px;
    box-shadow: 0 2px 4px 0 rgb(224, 224, 224);
    border-top: 1px solid #bfc6ce;
}
li.divider {
    margin: 5px 0
}
.product-images {
    position: relative
}
.search-list ul li {
    padding: 6px 5px;
    font-size: 12px
}
.search-list ul li a {
    color: #000
}
.search-list ul li a span {
    padding-left: 15px;
    color: #FF9800
}
.add-to-cart.fixed {
    bottom: 0;
    top: auto;
    z-index: 99
}
.add-to-cart.fixed .btn {
    width: 50%;
    float: left;
    border-radius: 0
}
.add-to-cart.fixed .add-to {
    background: #FF9800
}
#product .slider {
    width: 80%;
    margin: 0 auto
}
ul.tabs.product-detail {
    border-bottom: 1px solid #e0e0e0;
}
.footer-nav ul.bottom {
    background: #fff;
    padding-left: 5px
}
.footer-nav ul.bottom a {
    padding: 0;
    font-size: 11px;
    color: #000
}
nav.inner-page-header i,nav .button-collapse i {
    display: initial;
    height: auto;
    line-height: normal;
    
}
nav.inner-page-header {
    position: fixed;
    line-height: 52px!important;
    z-index: 1000;max-width: 900px;
}
.inner-page-header a.logo {
    position: absolute;
    left: 35px;
    padding-left: 0
}
.inner-page-header a.logo img {
    width: 30px;
    /*margin-top: 9px*/
}
.footer-nav ul.bottom i {
    vertical-align: middle;
    margin-right: 10px
}
.footer-nav ul.bottom li:first-child,
.footer-nav ul.bottom li:nth-child(2) {
    border-right: 1px solid #ccc
}
nav.product,
ul.list li.border-bottom {
    border-bottom: 1px solid #ccc;
    z-index: 999;
    box-shadow: 0 0 4px #ddd
}
#category .container.list {
    width: 100%
}
#category.section,
nav.inner-page-header+div.section {
    padding-top: 6.5rem
}
.pt-5rem {
    padding-top: 5rem
}
ul.grid p.product-name,
ul.list p.product-name {
    margin-top: 5px;
    height: auto;
    margin-bottom: 0
}
ul.grid p.product-name {
    margin-bottom: 5px
}
ul.list p.product-name+span {
    font-size: 10px;
    margin-bottom: .5rem;
    display: block
}
span.review,
ul.grid span.review,
ul.list span.review {
    letter-spacing: -4px
}
span.review i,
ul.grid span.review i,
ul.list span.review i {
    font-size: 14px
}
i.fa {
    font-size: 21px
}
a.company-name {
    margin: 0;
    padding-right: 0;
    padding-left: 20px;display: none;
}
.mr-5 {
    margin-right: 5px
}
.ml-5 {
    margin-left: 5px
}
.mr-15 {
    margin-right: 15px
}
.ml-15 {
    margin-left: 15px
}
.mt-10 {
    margin-top: 10px
}
.mt-15 {
    margin-top: 15px
}
.ml-10 {
    margin-left: 10px!important;
}
.mr-10 {
    margin-right: 10px
}
.mt-30 {
    margin-top: 30px
}
.no-bm {
    margin-bottom: 0
}
.no-pl {
    padding-left: 0!important
}
.mb-15 {
    margin-bottom: 15px
}
.mb-30 {
    margin-bottom: 30px
}
.ml-30 {
    margin-left: 30px
}
.no-lm {
    margin-left: 0!important
}.pl-10{
  padding-left: 10px!important;
}
.fs-1rem{
  font-size: 1rem;
}
ul.grid span.old-price,
ul.list span.old-price {
    display: initial
}
ul.grid span.offer,
ul.list span.offer {
    background: rgba(35, 47, 62, 0.5);
    color: #fff;
    border-radius: 4px;
    padding: 0 5px
}
#slide-filter li h6,
#sort-by h6 {
    color: #FF9800
}
ul.grid li {
    text-align: center
}
#category.list {
    background: #fff
}
#category.grid {
    background: #f2f2f2;
    min-height: 100vh
}
#category ul.grid li {
    background: #fff;
    border-radius: 2px;
    padding: 10px 15px;
    border-bottom: 6px solid #f2f2f2
}
#category ul.grid li:nth-child(2n+1) {
    border-right: 3px solid #f2f2f2;
    clear: both
}
#category ul.grid li:nth-child(n+2) {
    border-left: 3px solid #f2f2f2
}
ul.grid {
    margin-bottom: 0
}
#sort-by h6 {
    text-transform: uppercase;
    margin-left: 5px
}
.pl-0 {
    padding-left: 0!important
}
#slide-filter a.btn {
    padding: 0 10px!important;
    height: 40px;
    line-height: 40px
}
.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    z-index: 99999;
   
}
nav.fixed{
    top: -50px;
    transform: translateY(50px);
    transition: transform 0.3s linear;
}
#slide-filter li.padding {
    padding: 10px 30px
}
.filter-option {
    display: flex;
    flex-wrap: wrap;
}
.filter-option > div {
    background: #DDD;
    padding: 10px;
    border-radius: 5px;
    line-height:normal!important;
    margin:1px;
}
.filter-option > div.active{
    background: #1565c0;
    color: #fff;
}
.card,
.search-results {
    background-color: #fff
}
.category u.bottom,
.white-bg {
    background: #fff
}
.noUi-tooltip {
    line-height: normal
}
nav ul#notification {
    height: 34px;
    border-radius: 2px
}
@media only screen and (max-width: 468px) {
    .section .container {
        width: calc(100% - 12px);
        overflow: hidden
    }

    #quick-look .container,#manage-orders .container{
        width: 100%!important;
    }
    #quick-look .container .collapsible{
        margin: 0px;border:none;
    }
}
.collapsible-header{
    border-radius: 0px;
}

    .nav-wrapper.container:not(.category) {
        width: calc(100% - 12px);
        overflow: hidden
    }
    .nav-wrapper.container.category {
        width: 100%
    }
    nav .brand-logo {
        left: 50px;
    }
    nav .shop-name{
      margin-left: 35px;font-size: 16px;
      height: 52px;
    line-height: 52px;
    }
   
    nav.fixed .brand-logo {
        left: 56px!important
    }
    nav .brand-logo img {
        width: 30px;
        margin-top: 9px
    }
.search-wrapper {
    margin: 0 12px;
    -webkit-transition: margin .25s ease;
    transition: margin .25s ease
}
.card {
    position: relative;
    margin: 0 0 .5rem;
    -webkit-transition: -webkit-box-shadow .25s;
    transition: -webkit-box-shadow .25s;
    transition: box-shadow .25s;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2)
}
.search-wrapper input#search,
.search-wrapper input.search-box {
    display: block;
    font-size: 13px;
    font-weight: 300;
    border: 0;
    margin: 0;
    width: 100%;
    color: #000
}
::-webkit-input-placeholder {
    color: #000
}
::-moz-placeholder {
    color: #000
}
:-ms-input-placeholder {
    color: #000
}
:-moz-placeholder {
    color: #000
}
.search-wrapper input#search {
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 45px 0 15px
}
.search-wrapper input.search-box {
    padding: 0 45px 0 35px;
    height: 46px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 47px
}
.search-wrapper i.fa {
    position: absolute;
    right: 10px;
    cursor: pointer;
    color: #000
}
.search-wrapper i.fa:not(.clear) {
    top: 10px
}
.search-wrapper i.fa.clear {
    top: 12px
}
.search-results {
    margin: 0
}
.grey-bg {
    background: #f2f2f2
}
#footer-banner,
#index-banner {
    padding-top: 0;
}
.hot-sale {
    height: 120px;
    /*margin-bottom: .5rem*/
    margin-bottom: 3px;
    margin-top: -2px;
}
.hot-sale img {
    width: 100%;
    height: 100%
}
.slider {
    height: auto
}
.quick-category {
    margin-top: 1rem;
}
.slider .indicators {
    z-index: 998
}
.slider .indicators .indicator-item {
    height: 10px;
    width: 10px;
    margin: 0 5px
}
.offer-zone,
.quick-category {
    display: inline-block;
    overflow: auto;
    overflow-y: hidden;
    max-width: 100%;
    white-space: nowrap
}
.quick-category::-webkit-scrollbar { 
    display: none; 
}
ul.offer-zone li,
ul.quick-category li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    white-space: normal
}
ul.quick-category li {
    width: 100px
}
ul.offer-zone li {
    width: 90%;
    padding: 10px 7px 0 0;
}

ul.offer-zone li img{
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
    border-top: 1px solid #eaeaea;border-radius: 4px;
}
ul.quick-category li a span {
    color: #000;
    text-transform: capitalize;
    margin: 0 5px;display: block;
}
ul.quick-category li a img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    /*border: 1px solid #9e9e9e;*/
    /*padding: 3px*/
    filter: brightness(95%);
}
.quick-category-title {
    text-align: center;
    margin: 2rem 0 1rem;
    font-size: 16px
}
.products h6.category-title {
    color: #000;
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    text-align: left
}
.category-title,
.footer-nav .col,
.products .row.white-bg .col,
ol.progtrckr li span,
ul.category li.col,
ul.profile li {
    text-align: center
}
.products h6.category-title a.view-all {
    height: 30px;
    line-height: 30px;
    padding: 0 .5rem;
  /*  background: #fff;
    color: #000;*/
    text-transform: capitalize
}
.products .row.white-bg {
    background: #fff;
    margin: 1rem 0 0;
    border-radius: 2px
}
.products .row.white-bg .col:nth-child(2n+1) {
    border-right: 1px solid #f2f2f2
}
.products .row.white-bg .col:nth-child(n+3) {
    border-top: 1px solid #f2f2f2
}
p.product-name {
    font-size: 12px;
    margin-bottom: .5rem;
    margin-top: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden
}
span.price {
    font-size: 14px;
    color: #1565C0
}
span.old-price {
    font-size: 12px;
    color: #b9a8a8;
    text-decoration: line-through;
    display: block;
    margin-bottom: 1rem
}
.product-img {
    max-width: 100px;margin-top: 5px;
}
.slider .indicators .indicator-item.active {
    background-color: #FF9800
}
.footer-nav {
    background: #fff;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    padding: 10px 0;
    z-index: 99;
    box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, .2)
}
#manage-addresses div.address,
div.notification,
div.order,
div.wishlist,
ul.category li.col img {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2)
}
.no-margin {
    margin: 0!important
}
.footer-nav .col span {
    display: block
}
#notification li>a {
    color: #000;
    font-size: 14px;
    line-height: 13px;
    padding: 12px 16px;
    . line-height: 12px;
    height: 34px
}
.side-nav:not(.slide-filter) {
    background-color: #03396c
}
.side-nav.slide-filter {
    right: 0!important
}
.side-nav li>a {
    color: #fff
}
.side-nav li>a>i,
.side-nav li>a>i.fa {
    color: #fff;
    margin-right: 15px
}
.side-nav.slide-filter li>a {
    color: #000
}
.side-nav{
    z-index: 9999999 !important;
}
.category-title {
    margin: 1rem 0 1rem 0;
    font-size: 14px;
    color: #1565C0;text-transform: uppercase;
}
.category li span{
    display: block
}
ul.category li.col img {
    max-width: 100%;
    width: 85%;
    height: 85%;
    border-radius: 50%;
    border: 1px solid #9e9e9e;
    padding: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2)
}
ul.category li.col a {
    color: #000;
    text-transform: capitalize
}
ul.category li.col {
    margin-bottom: 30px
}
.nomargin {
    margin: 0
}
.nopad {
    padding: 0!important
}
ul.profile li {
    width: 100%;margin-top: 15px;
}
ul.profile li .user-view a i {
    color: #fff;
    font-size: 34px!important;
    padding: 7px;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 30px;
    margin: 10px auto 0
}
ul.profile li .user-view a {
    line-height: 30px
}
.name {
    font-size: 20px;
    font-weight: 700
}
ul.profile-nav {
    margin: 1em 1em 80px;
    background: #fff;
    border-radius: 4px
}
ul.profile-nav li {
    padding: 15px;
    border-bottom: 5px solid #f2f2f2
}
ul.profile-nav li i {
    vertical-align: top;
    width: 25px;
}
#share-on p label img,
.cart-option i,
.product-navigate i,
i.custom-icon img {
    vertical-align: bottom
}
ul.profile-nav li a {
    color: #000
}
#about-us h6 {
    font-size: 1.5em
}
#manage-addresses div.address,
div.notification,
div.order,
div.wishlist {
    background: #fff;
    padding: 5px 1.5rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    border-radius: 4px;
    margin-bottom: 10px
}
div .single-order,
div.order,
div.wishlist {
    padding: 10px 0!important
}
div.empty span i {
    border-radius: 50%;
    padding: 10px;
    font-size: 60px;
    color: #eab62e;
    background: #b1dcfb;
    border: 1px solid #f2f2f2;
    margin-top: 20%;
    width: 100px
}
div.empty h6 {
    font-size: 18px;
    margin: 30px 0 5px
}
div.order img,
div.wishlist img {
    max-width: 100px
}
div.order .cancel,
div.order .return {
    padding: 0 15px;
    height: 28px;
    line-height: 28px;
    margin-top: 10px
}
span.order-info {
    font-size: 13px
}
.color {
    color: #333;
}
.fs-20 {
    font-size: 20px
}
ol.progtrckr {
    margin: 40px 0 0;
    padding: 0
}
ol.progtrckr li {
    display: inline-block;
    position: relative
}
ol.progtrckr[data-progtrckr-steps="3"] li {
    width: 30%
}
ol.progtrckr li.progtrckr-done {
    color: #000;
    border-bottom: 4px solid #9acd32
}
ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver
}
ol.progtrckr li:after {
    content: "\00a0\00a0"
}
ol.progtrckr li:before {
    position: relative;
    bottom: -1.2em;
    float: left;
    left: 40%;
    line-height: 1em
}
ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: #fff;
    background-color: #9acd32;
    height: 2em;
    width: 2em;
    line-height: 2em;
    border: none;
    border-radius: 2em;
    text-align: center
}
.cart-pincode,
div.cart-box,
div.notification {
    background: #fff;
    border-radius: 4px
}
ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: #fff;
    font-size: 2em;
    bottom: -15px
}
ol.progtrckr li span {
    font-size: 90%;
    position: absolute;
    top: -20px;
    width: 100%;
    left: 0
}
ol.progtrckr li span.date {
    font-style: 80%;
    position: absolute;
    top: -5px;
    color: #FF9800
}

div.notification {
    padding: 5px 15px!important
}
.notification-title {
    margin-top: 5px!important
}
#sign-in input {
    padding-left: 5px
}
.fs-12 {
    font-size: 12px
}
div.cart-box {
    padding: 10px 20px!important;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2)
}
.border {
    border-bottom: 1px solid #ccc
}
.cart-option i {
    font-size: 20px;
    color: rgba(21, 101, 192, .42)
}
.br-2 {
    border-right: 2px solid #ccc
}
span.qty {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #0a3360;
    padding: 5px 10px;
    margin: 0 10px;
}
span.qty.active {
    color: #fff;
    background: #FF9800
}
.cart-price-detail .divider {
    margin: 5px 0
}
.add-to-cart {
    box-shadow: 0 -1px 15px 0 rgba(0, 0, 0, .2);
    background: #fff
}
.add-to-cart .amount {
    color: #333;
    background: #fff;
    text-align: left;
    font-size: 18px
}
.cart img {
    max-width: 75px
}
#footer-banner .category-title {
    text-align: left
}
.loader,
.payment-method {
    text-align: center
}
.footer-nav div.col {
    position: relative
}
span.footer-count,
span.header-count {
    position: absolute;
    background: #ff9800;
    font-size: 11px;
    color: #000
}
.footer-nav div.active {
    color: #FF9800
}
span.footer-count {
    top: -5px;
    right: 25%;
    padding: 1px 5px;
    border-radius: 50%
}
span.header-count {
    top: 10px;
    right: 12px;
    padding: 0 4px;
    border-radius: 50%
}
nav a {
    position: relative
}
.payment-method img {
    max-width: 100px
}

/*.payment-method span {
    display: block;
    color: #FF9800;
    font-weight: 600
}*/
.online-payment {
    border-right: 1px solid #FF9800
}
#quick-look {
    margin-bottom: 2rem
}
ul.category li:nth-child(3n+1) {
    clear: left
}
nav.fixed+.section {
    padding-top: 4.8rem!important
}
nav.fixed .nav-wrapper {
    width: 100%!important;
    padding: 0 6px
}
nav.fixed .search-wrapper {
    display: none
}
#notification {
    top: 10px!important
}
#search-modal,
.full-width-modal {
    top: 0!important;
    width: 100%;
    height: 100vh;
    max-height: 100%
}
#search-modal .modal-content {
    padding: 10px
}
#search-modal .search-wrapper i.left {
    position: absolute;
    left: 5px;
    top: 14px;
    right: auto
}
#search-modal .search-wrapper i.clear {
    position: absolute;
    left: auto;
    top: 14px;
    right: 15px
}
#search-modal .search-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999999
}
#search-list {
    margin-top: 2.3rem
}
[type=radio]:not(:checked)+label:after,
[type=radio]:not(:checked)+label:before {
    border: 2px solid #5a5a55
}
[type=radio]+label:after,
[type=radio]+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    -webkit-transition: .28s ease;
    transition: .28s ease
}
[type=radio].with-gap:checked+label:after,
[type=radio].with-gap:checked+label:before,
[type=radio]:checked+label:after,
[type=radio]:checked+label:before,
[type=radio]:not(:checked)+label:after,
[type=radio]:not(:checked)+label:before {
    border-radius: 50%
}
#slide-filter .btn-floating i {
    line-height: 40px!important
}
.padding-15 {
    padding: 15px!important
}
.width-50 {
    width: 50px!important
}
.width-100 {
    width: 100px!important
}
.height-100 {
    height: 100px!important
}
.margin-t-25 {
    margin-top: 25px!important
}
.search-in-cat {
    color: #2196F3!important;
    font-size: 13px!important;
    margin: 0!important;
    padding: 0!important
}
.active-filter {
    background: #f44336!important;
    color: #fff!important
}
.margin-10 {
    margin: 10px!important
}
#share-on p label {
    font-size: 12px!important
}
#share-on p label img {
    max-width: 20px;
    margin-right: 15px
}
.indicators:nth-of-type(2) {
    z-index: 999
}
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 999999
}
.margin-r-15 {
    margin-right: 15px!important
}
.btn.small {
    height: 38px;
    line-height: 38px
}
.loader .inner-box {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: auto;
    padding: 5px;
    background: #fff;
    -webkit-box-shadow: 0 12px 36px 16px rgba(0, 0, 0, .24);
    box-shadow: 0 12px 36px 16px rgba(0, 0, 0, .24)
}
.footer-nav.category {
    padding: 15px 0!important
}
.btn-primary {
    /*background: #FF9800;*/
    background: #ff9800;
    color: #fff
}
.btn-close,
.btn-floating.red {
    background: #f44336;
    color: #fff
}
.buy-now {
    background: #1565C0;
}
.pd-5 {
    padding: 5px;
}
.black-col {
    color: #000;
}
[href="#update-qty"] {
    margin: 0px 0 0 0!important;
    border: none!important;
    height: auto!important;
}
hr.cart-pg {
    border: 1px solid rgba(221, 221, 221, 0.31);
}
#logo-container,
span.header-count {
    line-height: normal
}

#review-and-rating {
    width: 100%;
    bottom: 0;
    top: auto!important;
    max-width: 900px;
}
h6.modal-title {
    text-transform: uppercase;
    font-weight: 600;
    color: #333;
    margin-left: 5px
}
.rating{
  transform: scaleX(-1);
  float: left;
}
.rating:not(:checked)>input {
    position: absolute;
    top: -9999px;
    clip: rect(0, 0, 0, 0)
}
.rating:not(:checked)>span {
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 40px;
    padding-right: 10px;
    color:#ddd;
   float: left;
}
span[for="star1"]{
  padding-left: 0px!important;
}

.rating:not(:checked)>span:before {
    content: '★ ';
}
.rating>input:checked~span {
    color: #f70;
}
.rating:not(:checked)>span:hover,
.rating:not(:checked)>span:hover~span {
    color: gold;
}
.rating>input:checked+span:hover,
.rating>input:checked+span:hover~span,
.rating>input:checked~span:hover,
.rating>input:checked~span:hover~span,
.rating>label:hover~input:checked~span {
    color: #ea0;
}
.rating>span:active {
    position: relative;
    top: 2px;
    left: 2px
}
#review-and-rating .modal-content{
  padding-bottom: 52px!important;max-width: 900px;
}
.product-navigate span i{
    font-size: 12px;
    border: 2px solid #ddd;
    padding: 5px;
    border-radius: 50%;
    vertical-align: baseline;
    background: rgba(204, 204, 204, 0.31)
}

.padding-25 {
    padding: 25px !important;
}

.bg-green {
    background : #9fffa3a8 !important;
}

.margin-b-10 {
    margin-bottom: 10px !important;
}

.width-100-per {
    width: 100% !important;
}

.fs-17 {
    font-size: 17px !important;
}
.primary-color{
color: #0D47A1;
}
#reward-link{opacity: 0;    margin: 0;
    position: absolute; }
    .reward.wishlist{
        text-align: left;
    padding-left: 15px!important;text-transform: uppercase;
    }
.payu-bg {
  background-color: #84b210 !important;
  border-color: #84b210 !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}
.payment-method{
  width: 100%;padding: 10px;
}
.payment-method:not(:last-child){
    border-bottom: solid 2px #f0f0f0;

}
.payment-method.open:not(:last-child){
    border-bottom: solid 8px #f0f0f0;
}
.payment-div{
  margin: 10px -10px 0px -10px;
}
.payment-opt{
  padding: 10px 0;
}
.payment-method h3.title{
  font-size: 15px;margin:0px;
}
.payment-img{
      max-height: 24px;
    margin-left: 16px;
}

.payment-method > .payment-opt {
  display: none;
}

.payment-method.open > .payment-opt {
  display: block!important;
}

.payment-method.cod{
  padding-bottom: 15px;    
  border-bottom: solid 2px #f0f0f0;

}
.payment-method label{
    font-size: 1.2rem!important;
        line-height: 34px!important;
    font-weight: bold;
}
.payment-method h3.title{
    text-align: left!important
}
p.payment-option{
  border-radius: 0px !important;
      border-radius: 0px !important;
    width: 100%;
    margin-bottom: 0px;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #1565c0;
    border-radius: 100%;
    background: #fff;margin-left: 0px;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 16px;
    height: 16px;
    background: #1565c0;
    position: absolute;
    top: 4px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.razorpay-bg{
    background:#145abe!important;
}
#loginAndregister.modal.bottom-sheet{
    padding:20px 20px 80px 20px !important;
    max-height: 80%!important;
}
#category1 {
    margin-bottom: 30px;
}
ul.quick-category{
    display: flex;margin-bottom: 0px;
}

ul.quick-category li{
    max-width: 30%;
    width: 30%;
    flex: 0 0 30%;
    border: 2px solid #f2f2f2;
    margin-right: 5px;
    background: #fff;
    padding: 10px 0;
}

.section.products{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
    border-top: 1px solid #eaeaea;
    margin-top: 10px;
    background: #fff;
}
#footer-banner{
    padding: 0px;
}
div.section.products.last {
    margin-bottom: 45px!important;
}
#product{
    /*padding-left: 10px;padding-right: 10px;*/
}
#product > .container{
    width: 100%!important
}
#product > .container > * ,#product > *:not(.container){
    padding-left: 10px!important;
    padding-right: 10px!important;
}
.body-wrapper{
    max-height: 100vh;overflow: scroll;
}
.body-wrapper::-webkit-scrollbar{
    display: none;
}

h5.exchange{font-size: 16px;margin-bottom: 15px;}
#product p.price{margin-bottom: -10px;1435
}
nav ul a.search,nav ul a.wishlist{
    padding-right: 0px !important;
}
.inner-page-header > ul > li > a:not(.company-name),nav:not(.category) .button-collapse, nav .go-back{
    display: flex;align-items: center;align-content: center;
}
nav ul > li > a {
    display: flex;align-items: center;align-content: center;
}

.hot-deal-title{font-size: 18px;margin-top: 0px;}
.hot-deals .product-image img{
width: 100%;max-width: 200px;margin:0 auto;
}
.hot-deals > .col {
background: #fff;
border-bottom: 3px solid #f9f9f9;
}
.hot-deals .product-image.hot{
text-align: center;
}

.hot-deals h2.p-name{font-size: 16px;}


.variants .label {
display: inline-block;
background-color: #fff;
width: 100%;
border-radius: 2px;
padding: 5px!important;
font-size: 14px;
cursor: pointer;
color: #000;
text-align: center;
border:2px solid #999;
height: auto!important;line-height: auto!important;
}

.variants  .radio:checked + .label:not(.disabled) {   
    color: blue;
    border: 2px solid blue;
    font-weight: bold;
}
.variants  .radio + .label.disabled {
    cursor: not-allowed;
    border: 2px solid #ddd;
    opacity: 0.7;
}
.hidden{display: none;}
.variants label.label:after,.variants label.label:before{
    display: none;
}
#product_variants{
    margin-bottom: 0;margin-top: 15px;
}
#product_variants .col.s3{
    margin-bottom: 10px;padding: 0 0.25rem;
}
#product_variants div.row{
    margin-left: 0px;margin-right: 0px;
}
#myProgress {
  width: 100%;
    z-index: 999999999;
    position: fixed;
    top: 0;
}

#myBar {
  width: 1%;
  height: 3px;
  background-color: #ffc627;
}
#myProgress .blur{
    height: 100vh;opacity: 0.2;width: 100%;background: #fff;
}
.product-name span{
    color: #999;font-size: 12px;
}
.variants .option-title{
    font-size: 14px;
}

.row .col.s6{
    word-break: break-all;
}
.btn.small.pincode{
    max-width: 100%;
    line-height: 30px;
    height: auto;
}
.cart-items .product-name h6 span ,.name.checkout-info-name span,span.variants{
    color: green;
    font-size: 11px;display: block;font-weight: bold;margin-top: 5px;
}
.btn.small {
    height: 30px;
    line-height: 30px;
}